<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Avvisi </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Avvisi</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">
        <h1 class="text-center text-primary-light mb-2">I tuoi avvisi</h1>

        <b-table
          :fields="fields"
          :items="announcementProvider"
          :filter="filter"
          :current-page="currentPage"
          show-empty
          striped
          responsive
          @sort-changed="sortTable"
        >
          <template #empty>
            <h5 class="text-center text-primary-light my-2">
              Non sono stati creati avvisi.
            </h5>
          </template>

          <template #cell(opzioni)="data">
            <feather-icon
              icon="Trash2Icon"
              class="cursor-pointer text-danger"
              size="20"
              @click="deleteAnnouncement(data.item)"
            />
          </template>
        </b-table>
      </div>
    </b-card>
  </section>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BCard, BTable } from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
  },

  data: () => ({
    announcements: [],
    filter: null,
    sortBy: "lastid",
    sortDesc: false,

    fields: [
      {
        key: "id_notify",
        label: "id richiesta",
        sortable: true,
      },
      {
        key: "marca",
        sortable: true,
      },
      {
        key: "modello",
        sortable: true,
      },
      {
        key: "anno_max",
        label: "anno",
        sortable: true,
      },
      {
        key: "km_max",
        label: "km",
        sortable: true,
      },
      {
        key: "colore",
        label: "colore",
        sortable: true,
      },
      {
        key: "tipo_cambio",
        label: "tipo cambio",
        sortable: true,
      },
      {
        key: "alimentazione",
        label: "alimentazione",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    //Helpers

    loading: false,
    currentPage: 1,
    perPage: 15,
  }),

  methods: {
    async getAnnouncements(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getResellerAnnouncements(request_data);
        this.announcements = response;
      } catch (err) {
        console.debug(err);
      }
    },

    async deleteAnnouncement(announcement) {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("id_notify", announcement.id_notify);
      request_data.append("record_status", "deleted");

      try {
        await Requests.deleteAnnouncement(request_data);
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async announcementProvider() {
      await this.getAnnouncements();
      return this.announcements.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>
